<template>
	<div class="body">
		<Nav></Nav>
		<div class="main_bg">
			<div style="padding: 150px 200px;">
				<div class="title_main">
					{{QWER('在线评估我的贷款额度')}}
				</div>
				<div class="tips_main">
					{{QWER('本页面提交的数据可以在评测结果页面重新修改，请放心提交')}}
				</div>
				<el-form label-position="top" label-width="80px" style="margin-top: 30px;" :model="mainData"
					ref="ruleForm" :rules='ruleForm'>

					<div class="flex-row">
						<el-form-item :label="QWER('请选择贷款主体类型')"  prop="entity_type_id" >
							<el-select v-model="mainData.entity_type_id" clearable :placeholder="QWER('请选择贷款主体类型')">
								<el-option v-for="item in typeList" :key="item.id" :label="item.entity_type_name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="QWER('请输入贷款主体名称')"  prop="entity_name" style="margin-left: 20px;">
							<el-input v-model="mainData.entity_name" :placeholder="QWER('请输入贷款主体名称')"></el-input>
						</el-form-item>
						<el-form-item label="是否转贷" style="margin-left: 20px;">
							<el-radio v-model="mainData.is_relend" :label="1">{{QWER('是')}}</el-radio>
							<el-radio v-model="mainData.is_relend" :label="0">{{QWER('否')}}</el-radio>
						</el-form-item>
					</div>
					<div v-for="(item,index) in mainData.debt_info" v-if="mainData.is_relend==1">
						<div class="flex-row" style="align-items: center;">
							<el-form-item :label="QWER('请选择贷款类型')">
								<el-select v-model="item.purpose_id" clearable :placeholder="QWER('请选择贷款类型')">
									<el-option v-for="itemC in loanTypeFuzhai" :key="itemC.id" :label="itemC.loan_purpose"
										:value="itemC.id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item :label="QWER('请输入贷款金额')" style="margin-left: 20px;">
								<el-input v-model="item.total" :placeholder="QWER('请输入贷款金额')" type="number"
									@input="item.total=handleInputNumber($event)"></el-input>
							</el-form-item>
				
							<div style="margin-left: 20px;">
								<el-button class="lease-btn" type="info" v-if="mainData.debt_info.length==index+1"
									@click="addFuzhaiitem" icon="el-icon-plus" circle></el-button>
								<el-button v-if="mainData.debt_info.length>1" type="danger" class="lease-btn"
									@click="deleteFuzhaiItem(index)" icon="el-icon-delete" circle></el-button>
							</div>
						</div>
					</div>
					<div class="flex-row" v-if="mainData.is_relend==0">
						<el-form-item :label="QWER('请选择贷款用途')" prop="loan_type" >
							<el-select v-model="mainData.loan_type" clearable :placeholder="QWER('请选择贷款用途')">
								<el-option v-for="item in loanTypeList" :key="item.id" :label="item.loan_purpose"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<div>
							<el-form-item :label="QWER('请输入你期望新增贷款的金额')" style="margin-left: 20px;">
								<el-input v-model="mainData.target_total" type="number" :placeholder="QWER('请输入你期望新增贷款的金额')"></el-input>
							</el-form-item>
							<span style="font-size: 14px;color: #DE7C2B;line-height: 22px;padding-left: 20px;">
								*{{QWER('如不填写，系统将按照最大可能性测算')}}
							</span>
						</div>
					</div>
				</el-form>
				<div style="margin-top: 20px;">
					<el-button type='warning' @click="save">{{QWER('开始测算')}}</el-button>
					<div style="margin-top: 30px;">
						<span
							style="background: #FFF6EF;padding: 10px 15px;font-size: 14px;margin-top: 10px;border-radius: 6px;">
							<i class="el-icon-warning-outline"></i>
							{{QWER('稍后可以重新选择贷款主体和担保实体，以及增加贷款用途。')}}
						</span>
					</div>

				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import Nav from "@/components/Nav/Nav.vue";
	import {
		getEntityTypes,
		getLoanPurposes
	} from "@/api/loans/loans"
	export default {
		components: {
			Nav
		},
		data() {
			return {
				typeList: [],
				loanTypeList: [],
				
				tempFuzhaiData: {
					purpose_id: "", //债务类型
					total: "", //债务金额
					is_relend: 1 ,//是否转贷
					pawn_type:'',
					evaluate_value:'',
					building_cost:'',
					rent_total:'',
					presale_total:''
				},
				loanTypeFuzhai:[],
				ruleForm: {
					entity_type_id: [{
						required: true,
						message: this.QWER('请选择贷款主体类型'),
						trigger: 'change'
					}],
					entity_name: [{
						required: true,
						message:  this.QWER( '请输入贷款主体名称'),
						trigger: 'blur'
					}],
					loan_type: [{
						required: true,
						message:  this.QWER('请选择贷款类型'),
						trigger: 'change'
					}],
					target_total: [{
						required: true,
						message:  this.QWER('请输入贷款金额'),
						trigger: 'blur'
					}]
				},
				mainData: {
					is_relend:0,//是否转贷
					debt_info:[],
					is_single_main:1,//是否单个实体作为主贷
					entity_type_id: '', //请选择贷款主体类型
					entity_name: '', //请输入贷款主体名称
					loan_type: '', //请选择贷款类型
					target_total: '', //请输入贷款金额
					is_reorganization:'',//已有贷款处理方式
				}
			};
		},
		created() {
			this.getTypeList()
			this.addFuzhaiitem()
			localStorage.setItem("mainData", '')
			localStorage.setItem("entityTreeList", '')
		},
		methods: {
			QWER(message) {
				let temp =  this.$t(message)
				// if(temp==message){
				// 	let langs =localStorage.getItem('lang')
				// 	let langlist = []
				// 	if(langs){
				// 		langlist=JSON.parse(langs)
				// 	}
				// 	langlist.push(temp)
				// 	let temppp= langlist.filter((item, index, self) => self.indexOf(item) === index);
				// 	localStorage.setItem('lang',JSON.stringify(temppp))
				// 	console.log(temppp)
				// }
			   return temp
			},
			addFuzhaiitem() { //新增
				let temp = JSON.parse(JSON.stringify(this.tempFuzhaiData))
				this.mainData.debt_info.push(temp)
			},
			deleteFuzhaiItem(index) {
				this.$confirm(this.QWER('确定要删除吗?'), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.mainData.debt_info.splice(index, 1)
				        }).catch(() => {
				                   
				        });
				
			},
			getTypeList() {
				getEntityTypes().then(res => {
					this.typeList = res.data
				})
				getLoanPurposes({level:''}).then(res => {
					this.loanTypeList = res.data
				})
				getLoanPurposes({
					level: 1
				}).then(res => {
					this.loanTypeFuzhai = res.data
				})
			},
			handleInputNumber(value) {
				if (parseFloat(value) < 0) {
					return ''
				}
				return value.replace("e", '')
			},
			save() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$router.push({
							name: "Index",
							params: this.mainData
						});
					} else {
						return false;
					}
				});
			}
		}

	};
</script>

<style lang="scss" scoped>
	@import "@/baseScss/baseScss.scss";

	.body {
		height: 100vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #F5F5F5;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-col {
		display: flex;
		flex-direction: column;
	}

	.flex-1 {
		flex: 1;
	}

	.main_bg {
		background: #FFFFFF;
		height: 800px;
		box-shadow: 0px 0px 12px 0px rgba(243, 243, 243, 0.5);
		border-radius: 10px;
		margin: 30px;
		overflow: auto;
		
		
	}

	.title_main {
		font-weight: bold;
		font-size: 36px;
		color: #000000;
		line-height: 20px;
	}

	.tips_main {
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 20px;
		margin-top: 21px;
	}
</style>