<template>
	<div class="nav">
		<div class="logo">
			<img @click="$jump('/')"
				src="https://images.squarespace-cdn.com/content/v1/5d9e8255f3f124742d5f68d0/1570669350097-YGU3C3TLD9DHVJKKK2PR/Reginsun+Brochure+-+NEW+QRCODE.png?format=1500w"
				alt=""/>
		</div>
		<div style="flex: 1;color: white">1</div>
		<ul class="nav-list">
			<li v-for="(item, index) in navList" :key="item.index" class="nav-item" @click="jumpNav(item)"
				@mouseenter="onHover(index)" @mouseleave="hover = -1">

				<el-dropdown @command="jumpNav" :show-timeout="0" :hide-timeout="100" placement="bottom">
					<p :class="current == item.index ? 'nav-name active' : 'nav-name'">
						{{ item.name }}
					</p>
					<el-dropdown-menu slot="dropdown" v-show="item.option">
						<el-dropdown-item :command="ele.url" v-for="ele in item.option" :key="ele.name">
							<p style="font-size: 13px">{{ ele.name }}</p>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</li>
			<div v-if="userInfo.id" class="nav-btn" @click="jump('/login')">
				Login
			</div>
			<div v-else>
				<el-popover :visible-arrow="false" placement="bottom" width="178" trigger="click"
					popper-class="popover">
					
					<div class="sign-out" @click="out">Sign out</div>
					<div slot="reference" class="user">
						<i class="el-icon-user"></i>
						<span>{{ user.email }}</span>
						<i class="el-icon-arrow-down"></i>
					</div>
				</el-popover>
			</div>
		</ul>
	</div>
</template>

<script>
	import {
		getUserInfo,
		removeToken,
		removeUserInfo
	} from "@/utils/myAuth";
	import {
		smsCode
	} from "@/api/login/login.js";
	export default {
		data() {
			return {
				// 导航列表
				navList: [{
						name: "HOME",
						index: 1,
						url: "https://reginsun.com/",
					},
					{
						name: "ABOUT US",
						index: 2,
						url: "https://reginsun.com/reginsun-group",
						option: [{
								name: "REGINSUN GROUP",
								url: "https://reginsun.com/reginsun-group",
							},
							{
								name: "WHAT WE DO",
								url: "https://reginsun.com/what-we-do",
							},
							{
								name: "OUR TEAM",
								url: "https://reginsun.com/our-team",
							},
							{
								name: "QUALIFICATIONS",
								url: "https://reginsun.com/our-qualifications",
							},
						],
					},
					{
						name: "CREDIT ASSISTANCE",
						index: 3,
						url: "https://reginsun.com/business-commercial",
						option: [{
								name: "COMMERCIAL LOANS",
								url: "https://reginsun.com/business-commercial",
							},
							{
								name: "HOME LOANS",
								url: "https://reginsun.com/home-loans",
							},
							{
								name: "DEVELOPMENT FINANCE",
								url: "https://reginsun.com/development",
							},
							{
								name: "EQUIPMENT FINANCE",
								url: "https://reginsun.com/equipment-finance",
							},
						],
					},
					{
						name: "FUNDS MANAGEMENT",
						index: 4,
						url: "https://reginsun.com/trustee-services",
						option: [{
							name: "TRUSTEE SERVICES",
							url: "https://reginsun.com/trustee-services",
						}, ],
					},
					{
						name: "SUCCESS STORIES",
						index: 5,
						url: "https://reginsun.com/success-stories",
					},
					{
						name: "BLOG",
						index: 6,
						url: "https://reginsun.com/blog",
					},
					{
						name: "HISTORY",
						index: 7,
						url: "/history",
					},
				],

				userInfo: {
					id: "",
				},
				user: {},
				current: 1,
				hover: -1,
			};
		},
		created() {
			this.init();
		},
		methods: {
			async init() {
				const user = JSON.parse(getUserInfo());
				this.userInfo.id = !user ? 1 : "";
				this.user = user;
				this.current = this.index;
			},
			jump(url) {
				if (url == this.$route.path) return;
				this.$jump(url);
			},
			jumpNav(command) {
				if (command.index == 7) {
					this.$jump(command.url);
				} else {
					window.open(command.url);
				}

			},
			onHover(index) {
				this.hover = index;
			},
			async out() {
				let loading = this.$loading()
				await removeToken();
				await removeUserInfo();
				this.userInfo.id = "";
				setTimeout(() => {
					this.$router.push("/login");
					loading.close()
				}, 300)
			},
		},
		props: ["now"],
	};
</script>

<style lang="scss" scoped>
	@import "@/baseScss/baseScss.scss";

	.nav {
		@font-face {
			font-family: "proxima nova";
			src: url("/assets/proxima-nova.otf");
		}
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 85px !important;
		flex-shrink: 0;
		background-color: #fff;
		box-sizing: border-box;
		padding: 0 20px;
		z-index: 100;
		min-width: 1650px;
		background-color: #fff;
		font-family: "proxima nova";
		box-shadow: 0px 0px 10px 0px #D8D8D8;

		.logo {
			cursor: pointer;
			width: 200px;
			height: 45px;
			margin-top: 18px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.nav-list {
			display: flex;
			align-items: center;
			height: 100%;

			.nav-item {
				display: flex;
				align-items: center;
				padding: 0 16px;
				height: 39px;
				position: relative;
				cursor: pointer;

				.nav-name {
					@include center;
					// display: flex;
					transition: color 0.1s 0s ease-in-out;

					font-weight: 400;
					font-style: normal;
					font-size: 13px;
					height: 40px;
					letter-spacing: 1px;
					text-transform: uppercase;
					text-decoration: none;
					white-space: nowrap;
					line-height: 1em;
					color: rgba(97, 97, 97, 0.9);

					&:hover {
						color: $color;
					}
				}

				.nav-dropdown {
					position: absolute;
					top: 80px;
					background-color: #fff;
					left: 0;
					font-family: "proxima nova";
					font-weight: 400;
					font-style: normal;
					font-size: 13px;
					letter-spacing: 1px;
					text-transform: uppercase;
					text-decoration: none;
					line-height: 1em;
					color: rgba(97, 97, 97, 0.9);
				}

				.el-dropdown {
					@include center;
					height: 40px;

					span {
						@include center;
						height: 40px;
					}
				}

				.active {
					color: $color;
				}
			}

			.nav-btn {
				@include center;
				@include mask;
				cursor: pointer;
				width: 132px;
				height: 39px;
				border-radius: 5px;
				font-size: 13px;
				color: #fff;
				margin-left: 13px;
				background-color: #de7c2b;
				font-family: "proxima nova";
				font-weight: 400;
				font-style: normal;
				letter-spacing: 1.5px;
				text-transform: uppercase;
				text-decoration: none;
			}

			.user {
				cursor: pointer;

				span {
					margin: 0 5px;
				}
			}
		}
	}

	.mask {
		@include center;
		background-color: rgba(31, 37, 38, 0.35);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;

		.nav {
			@include between;
			height: 85px;
			position: sticky;
			top: 0;
			background-color: #fff;
			box-sizing: border-box;
			padding: 0 20px;
			z-index: 100;

			.logo {
				width: 200px;
				height: 45px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nav-list {
				display: flex;
				align-items: center;

				.nav-item {
					display: flex;
					align-items: center;
					padding: 0 13px;
					cursor: pointer;

					.el-dropdown {
						@include center;
					}

					.nav-name {
						@include center;
						font-size: 13px;
						color: rgba(97, 97, 97, 0.9);
						height: 30px;

						&:hover {
							color: $color;
						}
					}

					.active {
						color: $color;
					}
				}

				.nav-btn {
					@include center;
					@include mask;
					cursor: pointer;
					width: 132px;
					height: 39px;
					border-radius: 5px;
					font-size: 13px;
					color: #fff;
					margin-left: 10px;
					background-color: #de7c2b;
				}
			}
		}
	}

	::v-deep .popper__arrow {
		display: none !important;
	}

	::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
		background-color: #fff !important;
		color: $color;
	}
</style>