<template>
    <div class="body">
        <Nav now="1"></Nav>
        <!-- 记录 -->
        <div class="history">
			<div style="display: flex;flex-direction: row;justify-content: space-between;">
				<div class="title">{{QWER('评测记录')}}</div>
				<div style="flex: 1;"></div>
				 <el-button type="info" @click="back">{{QWER('返回')}}</el-button>
				 <el-button type="primary" @click="submit">{{QWER('再次测评')}}</el-button>
			</div>
			<div style="margin-top: 40px;" v-for="(item,index) in tableData"> 
				<div style="display: flex;flex-direction: row;">
					<div class="table-title">{{item.created_at}}</div>
					<div class="status_bg2" v-if="item.status==1">{{QWER('已完成')}}</div>
					<div class="status_bg1" v-else>{{QWER('评测中')}}</div>
					<el-button type="warning" size="mini" @click="downloadResult(item)" style="margin-left: 20px;">{{QWER('下载填报数据')}}</el-button>
				</div>
				<div style="margin-top: 25px;">
					<div class="table-h1-bg table-flex">
						<div class="table-h1">{{QWER('贷款用途')}}</div>
						<div class="table-h1">{{QWER('贷款目标金额')}}</div>
						<div class="table-h1">{{QWER('转贷金额')}}</div>
						<div class="table-h1">{{QWER('新增贷款')}}</div>
						<div class="table-h1">{{QWER('您选择的实体组合')}}</div>
						<div class="table-h1">{{QWER('系统推荐最大组合')}}</div>
						<div class="table-h1">{{QWER('系统推荐最优组合')}}</div>
						<div class="table-h1"></div>
					</div>
					<div class="table-flex" v-for="(item2,index2) in item.main_lists">
						<div class="table-text">{{item2.purpose_name}}</div>
						<div class="table-text">{{item2.target_total}}</div>
						<div class="table-text">{{item2.on_lending_total}}</div>
						<div class="table-text">{{item2.add_total}}</div>
						<div class="table-text">{{item2.user_sub_total}}</div>
						<div class="table-text">{{item2.sys_max_total}}</div>
						<div class="table-text">{{item2.sys_optimal_total}}</div>
						<div class="table-text">
							<el-button type="info" size="mini" @click="showResult(item2)">{{QWER('查看组合')}}</el-button>
							
						</div>
					</div>
				</div>
			</div>
			<div v-if="total>tableData.length" style="width: 100%;text-align: center;">{{QWER('加载中')}}...</div>
			<div v-if="total==tableData.length" style="width: 100%;text-align: center;">{{QWER('已全部加载')}}</div>
        </div>
    
		<el-dialog
		  :visible.sync="showDetailDialog"
		  :show-close='false'
		  width="50%">
		  <div>
			  <div class="title">{{QWER('实体组合')}}</div>
			  <div class="table-h1-bg table-flex" style="margin-top: 20px;">
			  	<div class="table-h1"></div>
			  	<div class="table-h1">{{QWER('贷款主体')}}</div>
			  	<div class="table-h1">{{QWER('担保实体')}}</div>
			  </div>
			  <div class="table-flex">
			  	<div class="table-h1-1">{{QWER('您选择的实体组合')}}</div>
			  	<div class="table-text">{{detail.user_sub_com?detail.user_sub_com.entity_name:''}}</div>
			  	<div class="table-text">{{detail.user_sub_com?detail.user_sub_com.guaranteed_entitys:''}}</div>
			  </div>
			  <div class="table-flex">
			  	<div class="table-h1-1">{{QWER('系统推荐最大组合')}}</div>
			  	<div class="table-text">{{detail.sys_max_com?detail.sys_max_com.entity_name:''}}</div>
			  	<div class="table-text">{{detail.sys_max_com?detail.sys_max_com.guaranteed_entitys:''}}</div>
			  </div>
			  <div class="table-flex">
			  	<div class="table-h1-1">{{QWER('系统推荐最优组合')}}</div>
			  	<div class="table-text">{{detail.sys_optimal_com?detail.sys_optimal_com.entity_name:''}}</div>
			  	<div class="table-text">{{detail.sys_optimal_com?detail.sys_optimal_com.guaranteed_entitys:''}}</div>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="showDetailDialog = false">{{QWER('关闭')}}</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
import Nav from "@/components/Nav/Nav.vue";
import {loanEvaluationList,entityCombination,exportLoanRecord} from "@/api/loans/loans";
export default {
    data() {
        return {
            tableData: [],
			total:0,
			nowPages:1,
			pageSize:10,
            info: {},
            infoList: [],
			showDetailDialog:false,
			detail:{},
            show: false,
            loading: false,
        };
    },
    created() {
        this.init();
    },
    methods: {
		QWER(message,obj1) {
			let temp =  this.$t(message)
			if(obj1){
				temp = temp.replace('xxxxxx',obj1)
			}
		   return temp
		},
        async init() {
			if(this.nowPages==1){
			
				this.loading = true;
			}
            const data = {
                page: this.nowPages,
            };
            const list = await loanEvaluationList(data);
			console.log(list)
			if(this.nowPages==1){
				this.tableData = list.data ? list.data.data : [];
			}else{
				this.tableData = this.tableData.concat(list.data.data)
			}
            
            this.total = list.data ? list.data.total : 0;
			if(this.total>this.tableData.length){
				setTimeout(()=>{
					this.nowPages++
					this.init()
				},2000)
			}
            this.loading = false;
        },
		async showDetail(item){
			let loading=this.$loading()
			const detail =await entityCombination({id:item.id})
			loading.close()
			if(detail.code==200){
				this.detail = detail.data
				this.showDetailDialog=true
			}else{
				this.$message({
					type:'error',
					message:detail.msg
				})
				
			}
			
		},
		back(){
			this.$router.push('/index')
		},
		showResult(item){
			let msg = this.QWER('想知道具体贷款组合，请与我们取得联系');
			this.$alert(msg, this.QWER('提示'), {
			          confirmButtonText: this.QWER('确定'),
			          callback: action => { }
			});
		},
		downloadResult(item){
			console.log(item)
			exportLoanRecord({id:item.id}).then(res=>{
				console.log(res)
				if(res.code==200){
					window.open(res.data)
				}else{
					this.$message({
						type:'error',
						message:res.data
					})
				}
			})
		},
		submit(){
			this.$router.push('/')
		}
        
    },
    components: {
        Nav,
    },
};
</script>

<style lang="scss" scoped>
@import "@/baseScss/baseScss.scss";

.body {
	display: flex;
	height: 100vh;
	flex-direction: column;
	box-sizing: border-box;
	background: #F5F5F5;
    .history {
		flex:1;
		padding: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(243,243,243,0.5);
        border-radius: 10px;
		margin: 20px;
    }
	.title{
		font-weight: bold;
		font-size: 36px;
		color: #000000;
	}
	.table-title{
		font-weight: bold;
		font-size: 18px;
		color: #333333;
		margin-right: 40px;
	}
	.status_bg1{
		font-weight: 400;
		font-size: 18px;
		color: #DE7C2B;
		line-height: 22px;
	}
	.status_bg2{
		font-weight: 400;
		font-size: 18px;
		color: #43C93E;
		line-height: 22px;
	}
	.table-h1{
		font-weight: bold;
		font-size: 14px;
		flex: 1;
		color: #333333;
		line-height: 48px;
	}
	.table-h1-1{
		font-weight: bold;
		font-size: 14px;
		color: #333333;
		flex: 1;
		text-align: center;
		line-height: 61px;
	}
	.table-h1-bg{
		background: #F5F8FA;
		border-radius: 8px;
	}
	.table-flex{
		display: flex;
		flex-direction: row;
		text-align: center;
	}
	.table-text1{
		font-weight: 400;
		font-size: 14px;
		text-align: center;
		color: #666666;
		line-height: 61px;
	}
	.table-text{
		font-weight: 400;
		font-size: 14px;
		flex: 1;
		color: #666666;
		line-height: 61px;
	}
    
}


</style>
